import styled from "styled-components"

import { RadioGroup } from "@material-ui/core"

import { breakpoint } from "src/constants/breakpoints"
import { MCard } from "src/ui/MCard/MCard"
import { spacing } from "src/ui/spacing"

export const OnboardingCard = styled(MCard)`
  padding: ${spacing.L};
  max-width: 65ch;

  @media (${breakpoint.mediumUp}) {
    padding: ${spacing.XL2};
  }
`

export const CreateOrganizationStepWrapper = styled.form`
  display: grid;
  grid-gap: ${spacing.L};
  margin-top: ${spacing.L};
  container-type: inline-size;
`

export const RadioButtonsWrapper = styled(RadioGroup)`
  display: grid;
  grid-template-columns: 1fr;

  @container (${breakpoint.smallUp}) {
    grid-template-columns: 1fr 1fr;
  }
`
